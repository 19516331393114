import { useContext } from "react";
import { NotificationAlertContext, NotificationAlertContextType } from "../states/contexts/NotificationAlertContext";

const useAlert = () => {
    const context: NotificationAlertContextType = useContext(NotificationAlertContext);

    return context;
};

export default useAlert;
