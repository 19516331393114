import { FC } from "react";
import { FormControlLabel, FormGroup, Switch, SwitchProps, SxProps, styled } from "@mui/material";

type IProps = {
    name: string;
    checked: boolean;
    label?: string;
    labelPlacement?: "bottom" | "top" | "end" | "start";
    onChange: () => void;
    sx?: SxProps;
};

const SwitchButton: FC<IProps> = (props) => {
    const { name, checked, label, onChange, sx, labelPlacement } = props;

    return (
        <FormGroup>
            <FormControlLabel
                control={<BundleSwitch name={name} checked={checked} onChange={onChange} />}
                label={label}
                labelPlacement={labelPlacement}
                sx={{ userSelect: "none", gap: 2, ...sx }}
            />
        </FormGroup>
    );
};

const BundleSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 37,
    height: 21,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "200ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "#31AC37",
                opacity: 1,
                border: 0,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 16.8,
        height: 16.8,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "dark" ? "#232C35" : "#D2D6DA",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

export default SwitchButton;
