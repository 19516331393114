import { ChangeEvent, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { SelectInput } from "../../../../../../components";
import useLocales from "../../../../../../hooks/useLocales";

const SettingLanguageBlock = () => {
    const { translate, allLang, currentLang, onChangeLang } = useLocales();

    return (
        <Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{translate("language")}</Typography>
                <Box sx={{ width: 66 }}>
                    <SelectInput
                        value={currentLang.value}
                        options={allLang}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            onChangeLang(event.target.value);
                        }}
                        sx={{
                            ".MuiOutlinedInput-notchedOutline": { border: "none" },
                            ".MuiSelect-select": { fontWeight: 700 },
                            ".MuiSvgIcon-root": { color: "#000000" },
                        }}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default SettingLanguageBlock;
