import { Box, Dialog, DialogContent, DialogTitle, Grid, Hidden, IconButton, Slide, Typography } from "@mui/material";
import { CloseIcon } from "../../../../constants/icons";
import { Dispatch, FC, forwardRef, ReactElement, Ref, SetStateAction, useCallback } from "react";
import { TransitionProps } from "@mui/material/transitions";
import useThemeMode from "../../../../hooks/useTheme";
import { AuthState, authStore } from "../../../../features/authenticate/AuthStore";

type IProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const InfoDialog: FC<IProps> = (props) => {
    const { isOpen, setIsOpen } = props;
    const { theme }: any = useThemeMode();
    const authState: AuthState = authStore();

    const closeHandler = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <Dialog open={isOpen} onClose={closeHandler} TransitionComponent={Transition} maxWidth="sm" fullWidth id="setting-dialog">
            <DialogTitle>
                <Hidden smUp>
                    <IconButton aria-label="close" onClick={closeHandler} sx={{ position: "absolute", left: 20, p: 0 }}>
                        <CloseIcon color={theme.palette.settingDialog.icon} />
                    </IconButton>
                </Hidden>

                <Typography className="setting-dialog-title">Bilgiler</Typography>

                <Hidden smDown>
                    <Box className="setting-dialog-close-box" sx={{ background: theme.palette.settingDialog.background }}>
                        <IconButton aria-label="close" onClick={closeHandler}>
                            <CloseIcon color={theme.palette.settingDialog.icon} />
                        </IconButton>
                    </Box>
                </Hidden>
            </DialogTitle>

            <DialogContent id="setting-dialog-content">
                <Grid container spacing="45px">
                    <Grid item xs={12}>
                        <Typography>BundleId: {authState.bundleId}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>DeviceId: {authState.deviceId}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>AccessToken: {authState.accessToken}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>UserName: {authState.userName}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default InfoDialog;
