import { useTheme } from "../../../../../../hooks";
import { Box, Hidden, Typography } from "@mui/material";
import { ArrowWithoutLineIcon } from "../../../../../../constants/icons";
import { PhilipsIcon } from "../../../../../../constants/brandIcons";

const StatisticAdwert = () => {
    const { theme }: any = useTheme();

    return (
        <Box id="game-summary-adwert" sx={{ background: theme.palette.statisticDialog.gameAdwertBackground }}>
            <Hidden smDown>
                <img src="./assets/images/game-summary-adwert-logo.png" alt="" />
            </Hidden>

            <Box id="game-summary-adwert-content" sx={{ pl: { xs: "19px", sm: 0 } }}>
                <PhilipsIcon />

                <Typography id="game-summary-adwert-content-slogan" sx={{ color: theme.palette.statisticDialog.slogan }}>
                    Her seferinde lezzetli sonuçlar sunan yeni Airfryer 7000 XXL ile yemek yapmak hiç bu kadar kolay olmamıştı.
                </Typography>
            </Box>

            <Box id="game-summary-adwert-arrow">
                <ArrowWithoutLineIcon color={theme.palette.arrowColor} />
            </Box>
        </Box>
    );
};

export default StatisticAdwert;
