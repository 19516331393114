import { Fragment, useState } from "react";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { useLocales, useTheme } from "../../../../../../hooks";
import { LoginIcon, LogoutIcon } from "../../../../../../constants/icons";
import { AuthState, authStore } from "../../../../../../features/authenticate/AuthStore";

declare global {
    var BundleAndroid: {
        login: () => void;
        logout: () => void;
        setTheme: (color: string) => void;
        navigateToHomePage: () => void;
        navigateToPremium: () => void;
    };
}

const SettingLoginBlock = () => {
    const { theme, colors }: any = useTheme();
    const { translate } = useLocales();
    const authState: AuthState = authStore();
    const [isVisibleProgressBar, setIsVisibleProgressBar] = useState<boolean>(false);

    const loginHandler = () => {
        BundleAndroid?.login();
    };

    const logoutHandler = () => {
        setIsVisibleProgressBar(true);
        BundleAndroid?.logout();
        authState.useLogout();
    };

    if (isVisibleProgressBar) return <CircularProgress sx={{ color: "#31AC37", m: "auto" }} />;

    return authState.isAuthenticate ? (
        <Fragment>
            <Box>
                <Avatar src={authState.image} sx={{ width: 69, height: 69 }} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <Typography sx={{ fontSize: 26, fontWeight: 700 }}>{authState.userName}</Typography>

                    {/*<Box sx={{ cursor: "pointer" }} onClick={logoutHandler}>
                        <LogoutIcon color={theme.palette.settingDialog.icon} />
                    </Box>*/}
                </Box>
                {!authState.accessToken && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }} onClick={loginHandler}>
                        <Typography sx={{ color: colors.green[600], fontWeight: 600 }}>{translate("login")}</Typography>
                        <LoginIcon color={theme.palette.settingDialog.icon} />
                    </Box>
                )}
            </Box>
        </Fragment>
    ) : (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px", cursor: "pointer" }} onClick={loginHandler}>
                <Typography sx={{ fontSize: 26, fontWeight: 700 }}>{translate("login")}</Typography>
                <Box>
                    <LoginIcon color={theme.palette.settingDialog.icon} />
                </Box>
            </Box>
        </Box>
    );
};

export default SettingLoginBlock;
