import useThemeMode from "../../../../../hooks/useTheme";
import useLocales from "../../../../../hooks/useLocales";
import { CloseIcon } from "../../../../../constants/icons";
import { TransitionProps } from "@mui/material/transitions";
import { Dispatch, FC, ReactElement, Ref, SetStateAction, forwardRef, useCallback } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, Hidden, IconButton, Slide, Typography, useMediaQuery } from "@mui/material";
import {
    SettingBlindModeBlock,
    SettingHowToPlayBlock,
    SettingLanguageBlock,
    SettingLoginBlock,
    SettingThemeBlock,
    BundlePremiumChangeBlock,
} from "./common";

type IProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const SettingDialog: FC<IProps> = (props) => {
    const { isOpen, setIsOpen } = props;
    const { translate } = useLocales();
    const { theme }: any = useThemeMode();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const closeHandler = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <Box id="setting-dialog-box">
            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        background: { xs: theme.palette.settingDialog.xsBackground, sm: theme.palette.settingDialog.background },
                        borderRadius: { xs: "none", sm: "20px" },
                        mt: { xs: "none", sm: "90px" },
                        maxHeight: { xs: "auto", sm: "calc(100% - 80px)" },
                    },
                }}
                open={isOpen}
                onClose={closeHandler}
                fullScreen={fullScreen}
                TransitionComponent={Transition}
                maxWidth="sm"
                fullWidth
                id="setting-dialog">
                <DialogTitle>
                    <Hidden smUp>
                        <IconButton aria-label="close" onClick={closeHandler} sx={{ position: "absolute", left: 20, p: 0 }}>
                            <CloseIcon color={theme.palette.settingDialog.icon} />
                        </IconButton>
                    </Hidden>

                    <Typography className="setting-dialog-title">{translate("setting")}</Typography>

                    <Hidden smDown>
                        <Box className="setting-dialog-close-box" sx={{ background: theme.palette.settingDialog.background }}>
                            <IconButton aria-label="close" onClick={closeHandler}>
                                <CloseIcon color={theme.palette.settingDialog.icon} />
                            </IconButton>
                        </Box>
                    </Hidden>
                </DialogTitle>

                <DialogContent id="setting-dialog-content">
                    <Grid container spacing="67px" sx={{ px: "25px" }}>
                        <Grid item xs={12} className="setting-dialog-login-block">
                            <SettingLoginBlock />
                        </Grid>

                        <Grid item xs={12} className="setting-dialog-item-block">
                            <Grid container>
                                <Grid item xs={12} className="setting-dialog-item-block">
                                    <SettingThemeBlock />
                                </Grid>

                                <Grid item xs={12} className="setting-dialog-item-block" sx={{ marginTop: "50px !important" }}>
                                    <SettingBlindModeBlock />
                                </Grid>

                                <Grid item xs={12} className="setting-dialog-item-block" sx={{ marginTop: "50px !important" }}>
                                    <SettingLanguageBlock />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className="setting-dialog-item-block">
                            <BundlePremiumChangeBlock />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className="setting-dialog-item-block" sx={{ mt: "72px !important" }}>
                            <SettingHowToPlayBlock />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default SettingDialog;
