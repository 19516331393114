import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { responseHandler } from "../../middlewares/responseHandler";
import { IGetParams, IPostParams, IPutParams, IResponse } from "../../interfaces/Types";
import queryString from "query-string";

export const rawBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API,
    headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
    },
    prepareHeaders: (headers, { getState }) => {
        return headers;
    },
});

const dynamicBaseQuery = async (args: any, api: any, extraOptions: any) => {
    const result = await responseHandler(args, api, extraOptions);

    return result;
};

export const ApiSlice = createApi({
    reducerPath: "api",
    baseQuery: dynamicBaseQuery,
    keepUnusedDataFor: Number(process.env.REACT_APP_API_CACHE_SECOND_TIME),
    endpoints: (builder) => ({
        create: builder.mutation<IResponse, IPostParams>({
            query: ({ resource, params, headers }) => ({
                url: `/${resource}`,
                method: "POST",
                body: params,
                ...(headers && { headers }),
            }),
        }),
        update: builder.mutation<IResponse, IPutParams>({
            query: ({ resource, params, headers }) => ({
                url: `/${resource}`,
                method: "PUT",
                body: params,
                ...(headers && { headers }),
            }),
        }),
        get: builder.query<IResponse, IGetParams>({
            query: ({ resource, params, headers }) => ({
                url: `/${resource}?${queryString.stringify(params)}`,
                method: "GET",
                ...(headers && { headers }),
            }),
        }),
        getList: builder.query<IResponse, IGetParams>({
            query: ({ resource, params }) => `/${resource}?${queryString.stringify(params)}`,
        }),
    }),
});

export const { useCreateMutation, useUpdateMutation, useGetQuery, useGetListQuery } = ApiSlice;

export const {
    endpoints: { create, update, get, getList },
} = ApiSlice;
