import { Box, Button, Grid, Hidden, Typography } from "@mui/material";
import { SettingContext } from "../../states";
import "../../scss/screens/wordle-screen.scss";
import { useGuess, useTheme } from "../../hooks";
import { floatToFixedFloat } from "../../utils/methods";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { SettingContextType } from "../../states/contexts/SettingContext";
import { Adwert, Keyboard, SettingDialog, Sponsor, Wordle, WordleTimer } from "./common";
import { WordleStateLocale, wordleStore } from "../../features/wordle/WordleStore";
import { AuthState, authStore } from "../../features/authenticate/AuthStore";
import WordleScreenInitialHandler from "./common/WordleScreenInitialHandler";
import InfoDialog from "./common/dialogs/InfoDialog";

type IProps = {
    isCompleteSplashScreen: boolean;
};

const WordleScreen: FC<IProps> = (props) => {
    const { isCompleteSplashScreen } = props;
    const { theme } = useTheme();
    const settingContext: SettingContextType = useContext(SettingContext);
    const { guess, addGuessLetter }: any = useGuess();
    const wordleScreenBoxRef: any = useRef<any>(null);
    const [boxHeight, setBoxHeight] = useState<number>(0);
    const [boxWidth, setBoxWidth] = useState<number>(0);
    const [useEffectTrigger, setUseEffectTrigger] = useState<boolean>(false);
    const adwertRef: any = useRef<any>(null);
    const wordleTimerRef: any = useRef<any>(null);
    const sponsorRef: any = useRef<any>(null);
    const keyboardRef: any = useRef<any>(null);
    const [totalHeight, setTotalHeight] = useState<number>(0);
    const wordleState: WordleStateLocale = wordleStore();
    const authState: AuthState = authStore();
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setUseEffectTrigger((curr: boolean) => !curr);
        }, 100);

        const timeout = setTimeout(() => {
            clearInterval(interval);
        }, 300);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (wordleScreenBoxRef?.current) {
                const tmpRect: DOMRect = wordleScreenBoxRef?.current.getBoundingClientRect();

                setBoxHeight(tmpRect.height);
                setBoxWidth(tmpRect.width);
            }
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        if (wordleScreenBoxRef?.current) {
            const tmpRect: DOMRect = wordleScreenBoxRef?.current.getBoundingClientRect();

            setBoxHeight(tmpRect.height);
            setBoxWidth(tmpRect.width);
        }

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, [wordleScreenBoxRef.current, useEffectTrigger]);

    useEffect(() => {
        const handleResize = () => {
            let totalHeight: number = 0;

            if (adwertRef?.current) {
                const tmpRect: DOMRect = adwertRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (wordleTimerRef?.current) {
                const tmpRect: DOMRect = wordleTimerRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (sponsorRef?.current) {
                const tmpRect: DOMRect = sponsorRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (keyboardRef?.current) {
                const tmpRect: DOMRect = keyboardRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            setTotalHeight(totalHeight);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        let totalHeight: number = 0;

        if (adwertRef?.current) {
            const tmpRect: DOMRect = adwertRef?.current.getBoundingClientRect();

            totalHeight += tmpRect.height;
        }

        if (wordleTimerRef?.current) {
            const tmpRect: DOMRect = wordleTimerRef?.current.getBoundingClientRect();

            totalHeight += tmpRect.height;
        }

        if (sponsorRef?.current) {
            const tmpRect: DOMRect = sponsorRef?.current.getBoundingClientRect();

            totalHeight += tmpRect.height;
        }

        if (keyboardRef?.current) {
            const tmpRect: DOMRect = keyboardRef?.current.getBoundingClientRect();

            totalHeight += tmpRect.height;
        }

        setTotalHeight(totalHeight);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, [adwertRef.current, wordleTimerRef.current, sponsorRef.current, keyboardRef.current, authState.isPremium, useEffectTrigger]);

    const maxSize: number = floatToFixedFloat(boxHeight - totalHeight - parseFloat(theme.spacing(2)));

    return (
        <Grid container spacing={{ xs: 1, sm: 2 }} id="wordle-screen" ref={wordleScreenBoxRef}>
            <WordleScreenInitialHandler />

            {!authState.isPremium && (
                <Grid item xs={12} ref={adwertRef}>
                    <Adwert />
                </Grid>
            )}

            <Hidden smDown>
                <Grid item xs={12} ref={wordleTimerRef}>
                    <WordleTimer isCompleteSplashScreen={isCompleteSplashScreen} />
                </Grid>
            </Hidden>

            <Box id="wordle-screen-content">
                <Grid item xs={12} maxHeight={`${maxSize}px`}>
                    <Wordle guess={guess} sizeHeight={maxSize} sizeWidth={boxWidth} />
                </Grid>

                {!authState.isPremium && (
                    <Hidden mdDown>
                        <Grid item xs={12} ref={sponsorRef}>
                            <Sponsor />
                        </Grid>
                    </Hidden>
                )}

                <Grid item xs={12} ref={keyboardRef}>
                    <Keyboard addGuessLetter={addGuessLetter} />
                </Grid>

                {process.env.NODE_ENV === "development" && (
                    <Box sx={{ position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)" }}>
                        <Button
                            variant="contained"
                            onClick={(event: any) => {
                                wordleState.clear();
                                event.currentTarget.blur();
                            }}>
                            <Typography>Temizle (Test)</Typography>
                        </Button>
                    </Box>
                )}
                <Box sx={{ position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsInfoDialogOpen(true);
                        }}>
                        <Typography>Info</Typography>
                    </Button>
                </Box>
            </Box>

            {settingContext.isOpen && <SettingDialog isOpen={settingContext.isOpen} setIsOpen={settingContext.setIsOpen} />}
            {isInfoDialogOpen && <InfoDialog isOpen={isInfoDialogOpen} setIsOpen={setIsInfoDialogOpen} />}
        </Grid>
    );
};

export default WordleScreen;
