import { GameState } from "../utils/enums";
import { usePrevious } from "./usePrevious";
import { LETTER_LENGTH } from "../constants/settings";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { keyboardKeys } from "../screens/Wordle/common/Keyboard";
import { AddGuessResponse, WordleStateLocale, wordleStore } from "../features/wordle/WordleStore";
import useLocales from "./useLocales";
import useAlert from "./useAlert";
import { getErrorNotification, getGameEndNotification } from "../utils/methods";
import { GetGameNotificationMessageRes } from "../interfaces";
import i18n from "../i18n";

export const useGuess = () => {
    const wordleState: WordleStateLocale = wordleStore();
    const { translate, currentLang } = useLocales();
    const [guess, setGuess] = useState<string>("");
    const previousGuess: string = usePrevious<string>(guess);
    const { showAlert } = useAlert();

    const addGuessLetter = useCallback(
        (letter: string): void => {
            if (wordleState[currentLang.value].gameState !== GameState.PLAYING || (guess.length === 0 && letter === "Enter")) return;

            setGuess((currGuess: SetStateAction<string>) => {
                const currGuessStr: string = currGuess.toString();

                if (
                    !keyboardKeys
                        .flat()
                        .map((key: string) => key.toLocaleUpperCase())
                        .includes(letter.toLocaleUpperCase())
                )
                    return currGuessStr;

                const newGuess: string = letter.length === 1 && currGuessStr.length !== LETTER_LENGTH ? currGuessStr + letter : currGuessStr;

                switch (letter) {
                    case "Backspace":
                        return newGuess.slice(0, -1);
                    case "Enter":
                        if (newGuess.length === LETTER_LENGTH) {
                            return "";
                        } else {
                            showAlert(translate("not_enought_letter"), 3000, "🤔");
                        }
                }

                if (currGuessStr.length === LETTER_LENGTH) return currGuessStr;

                return newGuess;
            });
        },
        [wordleState[currentLang.value].gameState, currentLang]
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (wordleState[currentLang.value].gameState !== GameState.PLAYING) return;

            const letter: string = event.key;

            addGuessLetter(letter);
        },
        [wordleState[currentLang.value].gameState, addGuessLetter, currentLang]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [wordleState[currentLang.value].gameState, onKeyDown]);

    useEffect(() => {
        if (guess.length === 0 && previousGuess?.length === LETTER_LENGTH) {
            wordleState
                .addGuess(previousGuess, currentLang.value)
                .then((res: AddGuessResponse) => {
                    if (res.isGameEnded) {
                        const notificationResponse: GetGameNotificationMessageRes = getGameEndNotification(res.guessLength);

                        const message: string = res.word
                            ? `${notificationResponse.message}. ${i18n.t("current_day_word")} ${res.word}`
                            : notificationResponse.message;

                        showAlert(message, 3000, notificationResponse.icon);
                    }
                })
                .catch((err: any) => {
                    const notificationResponse: GetGameNotificationMessageRes = getErrorNotification(err);

                    showAlert(notificationResponse.message, 3000, notificationResponse.icon);
                });
        }
    }, [guess, previousGuess, currentLang.value]);

    return { guess, setGuess, addGuessLetter };
};
