import { useEffect } from "react";
import { AuthState, authStore } from "../../../features/authenticate/AuthStore";
import useThemeMode from "../../../hooks/useTheme";
import { ThemeMode } from "../../../utils/enums";
import { ApiSlice, useCreateMutation } from "../../../features/api/Api";

declare global {
    interface Window {
        bundleMobile: any;
    }
}

const WordleScreenInitialHandler = () => {
    const authState: AuthState = authStore();
    const [create] = useCreateMutation();

    /*
    const { mode }: any = useThemeMode();

    useEffect(() => {
        BundleAndroid?.setTheme(mode === ThemeMode.dark ? "#000000" : "#f4f5f6");
    }, [BundleAndroid]);
    */

    useEffect(() => {}, []);

    useEffect(() => {
        if (window.bundleMobile) {
            let isVisitedStr: string | null = localStorage.getItem("isVisited");

            if (!isVisitedStr) {
                isVisitedStr = "false";
                localStorage.setItem("isVisited", "true");
            }

            const isVisited: boolean = isVisitedStr === "true";

            if (!isVisited) {
                create({
                    resource: "dailywords/register-user",
                    params: {
                        ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }),
                        deviceToken: authStore.getState().deviceId,
                    },
                });
            }

            if (window.bundleMobile.accessToken && window.bundleMobile.bundleId) {
                authState.useLogin(window.bundleMobile.accessToken, window.bundleMobile.bundleId, window.bundleMobile.deviceId);

                create({
                    resource: "dailywords/check-devicetoken",
                    headers: {
                        ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }),
                        deviceToken: authStore.getState().deviceId,
                    },
                });
            } else authState.useAnonymousLogin(window.bundleMobile.anonymousUserId, window.bundleMobile.deviceId);

            create({
                resource: "dailywords/register-user",
                params: {
                    ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }),
                    deviceToken: authStore.getState().deviceId,
                },
            })
                .unwrap()
                .then((res: any) => {
                    authState.setUserInfo(res.data.userName, res.data.image, res.data.isPremium);
                });
        }
    }, [window.bundleMobile]);

    return null;
};

export default WordleScreenInitialHandler;
