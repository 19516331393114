import { Box, Button, Typography } from "@mui/material";
import { BackspaceIcon } from "../../../constants/icons";
import { FC, useCallback } from "react";
import { WordleStateLocale, wordleStore } from "../../../features/wordle/WordleStore";
import { GameState } from "../../../utils/enums";
import { useLocales, useTheme } from "../../../hooks";
import { getCharacterBackground, isExist } from "../../../utils/methods";

type IProps = {
    addGuessLetter: (letter: string) => void;
};

const Keyboard: FC<IProps> = (props) => {
    const { addGuessLetter } = props;
    const { theme, blindMode }: any = useTheme();
    const wordleState: WordleStateLocale = wordleStore();
    const { currentLang } = useLocales();

    const clickHandler = useCallback(
        (event: any) => {
            event.currentTarget.blur();
            const letter: string = event.currentTarget.getAttribute("data-key");

            if (letter === "") return;

            addGuessLetter(letter);
        },
        [addGuessLetter]
    );

    return (
        <Box id="keyboard">
            {keyboardKeys.map((row: string[], rowIndex: number) => (
                <Box
                    id="keyboard-row"
                    key={rowIndex}
                    sx={{
                        ...(rowIndex === 1 && { px: { xs: "12px", sm: "15px" } }),
                    }}>
                    {row.map((key: string, keyIndex: number) => {
                        const isButtonKey: boolean = key === "Enter" || key === "Backspace";

                        const background: string = isExist(wordleState[currentLang.value]?.keyboardLetterState[key])
                            ? getCharacterBackground(theme, wordleState[currentLang.value].keyboardLetterState[key], blindMode).background
                            : theme.palette.keyboard.keyBackground;

                        return (
                            <Button
                                id="keyboard-key"
                                className={`${isButtonKey ? "button-key" : ""} ${
                                    wordleState[currentLang.value]?.keyboardLetterState[key] ? "existLetter" : "nonExistLetter"
                                }`}
                                key={keyIndex}
                                sx={{
                                    background,
                                    cursor: key === "" ? "initial" : "pointer",
                                    "&:hover": {
                                        background: key === "" ? "none" : background,
                                    },
                                }}
                                onClick={clickHandler}
                                disabled={wordleState[currentLang.value].gameState !== GameState.PLAYING}
                                data-key={key}>
                                <Typography id="keyboard-key-text" sx={{ color: theme.palette.keyboard.text }}>
                                    {key === "Backspace" ? <BackspaceIcon color={theme.palette.keyboard.text} /> : key}
                                </Typography>
                            </Button>
                        );
                    })}
                </Box>
            ))}
        </Box>
    );
};

export default Keyboard;

export const keyboardKeys: string[][] = [
    ["e", "r", "t", "y", "u", "ı", "o", "p", "ğ", "ü"],
    ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ş", "i"],
    ["Enter", "z", "c", "v", "b", "n", "m", "ö", "ç", "Backspace"],
];
