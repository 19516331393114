import { FC, ReactNode, forwardRef, useEffect, useState } from "react";
import { NotificationAlertContext, NotificationAlertContextType } from "../contexts/NotificationAlertContext";
import { Box, Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

type Alert = {
    message: string;
    icon?: any;
    timer?: number;
};

type NotificationAlertContextProvider = {
    children: ReactNode;
};

const NotificationAlertProvider: FC<NotificationAlertContextProvider> = ({ children }) => {
    const [alert, setAlert] = useState<Alert>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const hideAlert = () => {
        setAlert({} as Alert);
        setIsOpen(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            hideAlert();
        }, alert?.timer ?? 3000);

        return () => clearInterval(interval);
    }, [alert?.timer]);

    const contextValue: NotificationAlertContextType = {
        showAlert: (message, timer, icon) => {
            setAlert({ message, icon, timer } as Alert);
            setIsOpen(true);
        },
    };

    return (
        <NotificationAlertContext.Provider value={contextValue}>
            <Dialog
                id="notification-dialog"
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={hideAlert}
                aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                    <DialogContentText className="content">
                        {alert?.message}
                        {alert?.icon && <Box className="content-icon">{alert?.icon}</Box>}
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {children}
        </NotificationAlertContext.Provider>
    );
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default NotificationAlertProvider;
