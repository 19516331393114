import "./i18n";
import App from "./App";
import "./scss/styles.scss";
import { StrictMode } from "react";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import { Root, createRoot } from "react-dom/client";

const root: Root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <StrictMode>
        <Provider store={store}>
            <CssBaseline />
            <App />
        </Provider>
    </StrictMode>
);
