import { format } from "date-fns";
import { useLocales, useTheme } from "../../../../../../hooks";
import { IPointTableLeaderBoard } from "../../../../../../interfaces";
import { Avatar, Badge, Box, Button, Divider, Typography } from "@mui/material";
import { PointTableLeaderIcon, PremiumIcon } from "../../../../../../constants/icons";
import { AuthState, authStore } from "../../../../../../features/authenticate/AuthStore";
import { useGetQuery } from "../../../../../../features/api/Api";
import CryptoJS from "crypto-js";
import { useState } from "react";
import { secondToDate } from "../../../../../../utils/methods";

const PointTable = () => {
    const { theme }: any = useTheme();
    const { translate } = useLocales();
    const authState: AuthState = authStore();
    const { currentLang } = useLocales();
    const [saltedInput] = useState<string>(`${format(new Date(), "yyyy-MM-dd")}${process.env.REACT_APP_SALT_KEY}`);

    const { data: leaderboardRes } = useGetQuery({
        resource: "dailywords/leaderboard",
        params: { ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }) },
        headers: {
            langCode: currentLang.value,
            currentDate: CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(saltedInput)).toString(CryptoJS.enc.Hex),
        },
    });

    return authState.isAuthenticate ? (
        <Box id="point-table">
            <Box id="point-table-header">
                <Typography id="point-table-header-title" sx={{ color: theme.palette.statisticDialog.leaderboardTitle }}>
                    {translate("pointPlacement")}
                </Typography>
                <Typography id="point-table-header-date">{format(new Date(), "dd.MM.yyyy")}</Typography>
            </Box>

            <Box id="point-table-leaderboard">
                <Box id="point-table-leaderboard-after" sx={{ "::after": { background: theme.palette.statisticDialog.leaderboardAfterBackground } }}>
                    {leaderboardRes?.data
                        .sort((a: IPointTableLeaderBoard, b: IPointTableLeaderBoard) => a.order - b.order)
                        .map((user: IPointTableLeaderBoard, index: number) => {
                            return (
                                <Box
                                    className="point-table-item"
                                    key={index}
                                    sx={{
                                        ...(index <= 2 && { borderColor: `${theme.palette.statisticDialog.leaderboardItemBorder} !important` }),
                                        background: user.isCurrentUser
                                            ? theme.palette.statisticDialog.leaderboardItemCurrUserBackground
                                            : theme.palette.statisticDialog.leaderboardItemBackground,
                                    }}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        badgeContent={
                                            index <= 2 && (
                                                <Box className="point-table-item-avatar-badge" sx={{ background: leaderIconColorMap[index] }}>
                                                    <PointTableLeaderIcon width={11} height={11} />
                                                </Box>
                                            )
                                        }>
                                        <Avatar
                                            sx={{
                                                ...(index <= 2
                                                    ? { background: user.isCurrentUser ? "#ffffff !important" : "#E67A2C" }
                                                    : { background: user.isCurrentUser ? "#ffffff !important" : "" }),
                                            }}>
                                            <Typography
                                                className="point-table-item-avatar-name"
                                                sx={{ color: user.isCurrentUser ? "#000000 !important" : "" }}>
                                                US
                                            </Typography>
                                        </Avatar>
                                    </Badge>

                                    <Box className="point-table-item-content">
                                        <Box className="point-table-item-content-info">
                                            <Typography
                                                className="point-table-item-content-info-placement"
                                                sx={{ color: user.isCurrentUser ? "#ffffff !important" : "" }}>
                                                {index < 9 && 0}
                                                {index + 1}
                                            </Typography>

                                            <Divider orientation="vertical" flexItem className="point-table-item-content-info-divider" />

                                            <Typography
                                                className="point-table-item-content-info-username"
                                                sx={{
                                                    color: user.isCurrentUser
                                                        ? "#ffffff !important"
                                                        : theme.palette.statisticDialog.leaderboardItemUsername,
                                                }}>
                                                {user.username}
                                            </Typography>
                                        </Box>

                                        <Typography
                                            className="point-table-item-time"
                                            sx={{ color: user.isCurrentUser ? "#ffffff !important" : index <= 2 ? "#31AC37" : "#B3BAC1" }}>
                                            {format(secondToDate(user.spentTime), "HH:mm:ss")}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
            </Box>
        </Box>
    ) : (
        <Box id="point-table-not-authenticate">
            <PremiumIcon color={theme.palette.statisticDialog.premiumIcon} />

            <Box>
                <Typography className="point-table-not-authenticate-title">{translate("pointTableTitle1")}</Typography>

                <Typography className="point-table-not-authenticate-title" sx={{ color: theme.palette.statisticDialog.notAuthenticateTitle }}>
                    {translate("pointTableTitle2")}
                </Typography>
            </Box>

            <Typography className="point-table-not-authenticate-desc">{translate("pointTableDesc1")}</Typography>

            <Typography className="point-table-not-authenticate-desc">{translate("pointTableDesc2")}</Typography>

            <Button
                variant="contained"
                id="point-table-not-authenticate-btn"
                sx={{ background: theme.palette.statisticDialog.notAuthenticateTitle }}
                onClick={() => authState.changePremium()}>
                {translate("pointTableSubscriptionBtn")}
            </Button>
        </Box>
    );
};

const leaderIconColorMap: { [key: number]: string } = {
    0: "#FFCA40",
    1: "#BFC9D2",
    2: "#DBA463",
};

export default PointTable;
