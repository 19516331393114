import { FC } from "react";
import { useTheme } from "../../hooks";
import { Box, LinearProgress, SxProps } from "@mui/material";

type IProps = {
    width: number | string;
    sx?: SxProps;
};

const BundleLoadingLineer: FC<IProps> = (props) => {
    const { width, sx } = props;
    const { theme }: any = useTheme();

    return (
        <Box className="bundle-loading-box" sx={{ ...sx, width }}>
            <LinearProgress className="bundle-loading-box-progress" sx={{ background: theme.palette.statisticDialog.loadingBackground }} />
        </Box>
    );
};

export default BundleLoadingLineer;
